const roleVO = function () {
  return {
    id: null,
    name: '',
    role: '',
    roleType: 'func',
    dataAuthType: 'dept',
    description: '',
    status: 'true',
    creater: '',
    dateCreated: '',
    lastUpdated: ''
  }
}

export default roleVO

export const roleType = [
  { label: '功能权限', value: 'func', color: 'blue' },
  { label: '数据权限', value: 'data', color: 'orange' }
]

export const roleDataType = [
  { value: 'user', label: '仅个人' }, // 不能选择部门
  { value: 'dept', label: '所在部门数据' }, // 不能选择部门
  { value: 'deptAll', label: '所在部门及以下数据' }, // 不能选择部门
  { value: 'deptOnly', label: '指定部门数据' },
  { value: 'unit', label: '所属单位数据' }, // 不能选择部门
  { value: 'all', label: '所有数据' } // 不能选择部门
]

