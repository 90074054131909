<template>
  <a-spin :spinning="spinShow">
    <a-form layout="vertical" :form="form">
      <a-form-item
        label="设置角色名称">
        <a-input
          v-decorator="['name',
                      {rules:[
                        {type:'string',required:true,message:'角色名称不能为空'},
                        {type:'string',max:30,message:'角色名称不能超过30个字符'},
                        ],trigger:'change'}
                      ]"
          placeholder="输入角色名称"></a-input>
      </a-form-item>
      <a-form-item required label="设置角色标示">
        <a-input v-decorator="['role',
                            {rules:[
                              {type:'string',validator:validate},
                              ],trigger:'change',validateTrigger:'blur'}
                            ]"
                 placeholder="输入角色标示"></a-input>
      </a-form-item>
      <a-form-item label="角色类型">
        <a-select
          placeholder="请选择角色类型"
          v-decorator="[ 'roleType',{rules: [{ required: true, message: '请选择角色类型'}],initialValue: formItem.roleType}]"
          @change="roleTypeHandle">
          <a-select-option
            v-for="item in roleType"
            :key="item.value"
            :value="item.value"
            :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item key="dataAuthTypeKey" label="授权类型" v-if="formItem.roleType !== 'func'">
        <a-radio-group :options="roleDataType"
                       v-decorator="['dataAuthType',{initialValue: formItem.dataAuthType, rules:[{type:'string',required:true,message:'类型为必填项'}],trigger:'change'}]"></a-radio-group>
      </a-form-item>
      <a-form-item
        label="设置角色描述">
        <a-textarea
          :autosize="{ minRows: 2, maxRows: 6 }"
          v-decorator="['description']"
          placeholder="输入角色描述"></a-textarea>
      </a-form-item>
      <a-form-item label="设置角色状态">
        <a-switch checkedChildren="启用" unCheckedChildren="禁用"
                  v-decorator="['status', {
                            rules:[{type:'boolean',required:true}],
                            trigger:'change',valuePropName:'checked',initialValue:true}]"></a-switch>
      </a-form-item>
      <a-form-item label="最近更新时间" v-if="type === 'edit'">
        {{formItem.lastUpdated}}
      </a-form-item>
      <a-form-item label="角色创建时间" v-if="type === 'edit'">
        {{formItem.dateCreated}}
      </a-form-item>
      <a-form-item label="角色创建人（用户Username）" v-if="type === 'edit'">
        {{formItem.creater}}
      </a-form-item>
    </a-form>
  </a-spin>
</template>
<script>

  import SERVICE_URLS from '../../../api/service.url'
  import roleVO from '../../../api/vo/settings/roleVO'
  import { roleType, roleDataType } from '../../../api/vo/settings/roleVO'

  export default {
    name: 'RoleForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    data () {
      return {
        formItem: roleVO(),
        spinShow: false,
        form: this.$form.createForm(this),
        ifSub: false,
        roleType: roleType,
        roleDataType: roleDataType
      }
    },
    methods: {
      validate (rule, value, callback) {
        if (!value) {
          callback(new Error('角色标示不能为空'))
          return
        }
        if (value.length > 30) {
          callback(new Error('角色标示不能超过30个字符'))
          return
        }
        callback()
        /*let url = SERVICE_URLS.role.check
        let params = {
          role: encodeURIComponent(value.trim())
        }
        if (this.formItem.id) {
          url = SERVICE_URLS.role.checkAndId
          params['id'] = this.formItem.id
        }
        this.$http(this, {
          url: url,
          params: params,
          noTips: true,
          noErrorTips: true,
          success: () => {
            callback()
          },
          error: (err, data) => {
            if (data) {
              callback(new Error(data.message))
            } else {
              callback('服务端验证请求失败！')
            }
          }
        })*/
      },
      /*
      * 通过ID获取数据
      */
      loadData (id) {
        const self = this
        this.$http(self, {
          url: SERVICE_URLS.role.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.setFields(data.body)
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          name: this.formItem.name,
          role: this.formItem.role,
          description: this.formItem.description,
          status: this.formItem.status,
          roleType: this.formItem.roleType,
          dataAuthType: this.formItem.dataAuthType
        })
      },
      setVOFields (values) {
        this.formItem.name = values.name
        this.formItem.role = values.role
        this.formItem.description = values.description
        this.formItem.status = values.status
        this.formItem.roleType = values.roleType
        this.formItem.dataAuthType = values.dataAuthType
      },
      submitEdit () {
        const {
          form: { validateFields, resetFields }
        } = this
        validateFields(['name', 'role', 'description', 'status', 'roleType', 'dataAuthType'],
          { force: true }, (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$emit('submitStart')
              this.$http(this, {
                url: SERVICE_URLS.role.update,
                data: this.formItem,
                params: {
                  id: this.formItem.id
                },
                success: (data) => {
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('editSuccess', data.body)
                  //清空表单数据
                  this.formItem = roleVO()
                  resetFields()
                },
                error: () => {
                  this.$emit('submitError')
                }
              })
            }
          })
      },
      submitAdd () {
        const {
          form: { validateFields, resetFields }
        } = this
        validateFields(['name', 'role', 'description', 'status', 'roleType', 'dataAuthType'],
          { force: true }, (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$emit('submitStart')
              this.$http(this, {
                url: SERVICE_URLS.role.create,
                data: this.formItem,
                success: (data) => {
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('addSuccess', data.body)
                  //清空表单数据
                  this.formItem = roleVO()
                  resetFields()
                },
                error: () => {
                  this.$emit('submitError')
                }
              })
            }
          })
      },
      roleTypeHandle (value) {
        this.formItem.roleType = value
      }
    },
    components: {}
  }
</script>
